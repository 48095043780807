@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.navbar{
  background-color: #131212 !important;
}
a {
  color: inherit;
  text-decoration: none;
}
.icons{
  font-size: 1.3rem;
}
li{
  text-decoration: none;
  color: white;
  list-style: none;
}
.home-container{
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}
.img-fluid{
  max-width: 100%;
}

.col-md{
  border: 0 rgb(255, 255, 255) solid;
  border-radius: 2%;
  background-color: #131212;
}
.bi-display, .bi-code-slash{
  font-size: 165%;
  margin: 5%;
  color: white;
}
.bi-display:hover, .bi-code-slash:hover{
  color: #0d6efd;
}
.bi-linkedin{
  margin-right: 15px;
}
canvas{
  background-color: rgba(0, 0, 0, 0.945);
  position:fixed !important;
  left:0;
  top:0;
  width:100%;
  height:100%;
    z-index:-1;
}
h1{
  font-weight: 900;
}
h1,h3,h4,b,p{
  color:white;
}
.home-container{
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}
/* -----BREAKPOINTS-----*/
.navbar-expand-md {
  flex-wrap: nowrap;
  justify-content: center;
}
@media (min-width: 768px){
  .col-md{
    margin: 1%;

  }
}
@media (max-width: 576px){
  .col-md{
    margin-top: 5%;
  }
}
/*# sourceMappingURL=App.css.map */
.App{text-align:center}.App-logo{height:40vmin;pointer-events:none}@media(prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:#fff}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}a{color:inherit;text-decoration:none}
label, h1, h2{
    text-align: center;
    color: white;
}
.contact-container{
    padding: 80px 0

}
form{
    position: relative;

}
.btn{
      position: absolute;
}
#contact-wrapper{
    padding: 100px;
}
input{
    margin-bottom: 3%;
}
.form-control{
    position: relative;
    width: 100em;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
@media (max-width: 576px){
    .form-control{
        position: relative;
        width: 100em;
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
    }
    input{
        margin-bottom: 10%;
    }
    .btn{
        position: absolute;
        left: 0 !important;
  }
  }
  @media (min-width: 768px){
    .btn{
        position: absolute;
        left: 25%;
  }
  }

