label, h1, h2{
    text-align: center;
    color: white;
}
.contact-container{
    padding: 80px 0

}
form{
    position: relative;

}
.btn{
      position: absolute;
}
#contact-wrapper{
    padding: 100px;
}
input{
    margin-bottom: 3%;
}
.form-control{
    position: relative;
    width: 100em;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
@media (max-width: 576px){
    .form-control{
        position: relative;
        width: 100em;
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
    }
    input{
        margin-bottom: 10%;
    }
    .btn{
        position: absolute;
        left: 0 !important;
  }
  }
  @media (min-width: 768px){
    .btn{
        position: absolute;
        left: 25%;
  }
  }
